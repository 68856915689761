<template>
	<SubPageLayout>
		<StackedPanels>
			<PanelSection>
				<h2 class="text-lg leading-6 font-medium text-gray-900">General settings</h2>

				<FormKit id="updateTemplateSettings" v-model="builderStore.template" :value="builderStore.template" :preserve="true" type="form" :actions="false" class="flex w-full">
					<div class="grid grid-cols-12 gap-x-2 gap-y-2 sm:gap-y-4 my-4">
						<FormKitSchema :schema="templateSettings" />
					</div>
				</FormKit>
				<FormKit id="updateGeneralSettings" v-model="builderStore.settings" :value="builderStore.settings" :preserve="true" type="form" :actions="false" class="flex w-full">
					<div class="grid grid-cols-12 gap-x-2 gap-y-2 sm:gap-y-4 my-4">
						<FormKitSchema :schema="generalSettings" />
					</div>
				</FormKit>
				<template #footer>
					<div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
						<button
							class="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 cursor-pointer"
							type="button"
							@click="saveForm"
						>
							Save
						</button>
					</div>
				</template>
			</PanelSection>
		</StackedPanels>
	</SubPageLayout>
</template>

<script setup lang="ts">
	import StackedPanels from '@components/StackedPanels.vue';
	import PanelSection from '@components/PanelSection.vue';
	import SubPageLayout from '@layouts/SubPageLayout.vue';
	import { TemplateType, TemplateTypeLabel } from '@/types/activityTemplate';
	import { useBuilderStore } from '@modules/builder/store';
	import { getNode, reset } from '@formkit/core';
	import { onBeforeUnmount } from 'vue';
	import { CardOption } from '@/types/form';

	const builderStore = useBuilderStore();

	const saveForm = () => {
		if (getNode('updateTemplateSettings')?.context?.state.valid && getNode('updateGeneralSettings')?.context?.state.valid) {
			builderStore.updateTemplate();
		}
	};

	const templateSettings = [
		{
			$formkit: 'text',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-6': true, 'xl:col-span-6': true },
			validation: [['required'], ['length:0,255']],
			id: 'name',
			name: 'name',
			label: 'Template name',
			placeholder: 'Enter the name of the template being created',
		},
		{
			$formkit: 'text',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true },
			id: 'description',
			name: 'description',
			label: 'Template description',
			placeholder: 'Enter the description of the template being created',
		},
		{
			$formkit: 'cardGroup',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true },
			validation: [['required']],
			id: 'type',
			name: 'type',
			label: 'Activity type',
			simple: true,
			cardOptions: [
				{
					label: TemplateTypeLabel[TemplateType.TICKET],
					value: TemplateType.TICKET,
				},
				{
					label: TemplateTypeLabel[TemplateType.TASK],
					value: TemplateType.TASK,
				},
				{
					label: TemplateTypeLabel[TemplateType.PROJECT],
					value: TemplateType.PROJECT,
				},
				{
					label: TemplateTypeLabel[TemplateType.JOB],
					value: TemplateType.JOB,
				},
				{
					label: TemplateTypeLabel[TemplateType.ACTIVITY],
					value: TemplateType.ACTIVITY,
				},
				{
					label: TemplateTypeLabel[TemplateType.WORK_ORDER],
					value: TemplateType.WORK_ORDER,
				},
			] as CardOption[],
		},
	];

	const generalSettings = [
		{
			$formkit: 'text',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-6': true },
			id: 'details_heading',
			name: 'details_heading',
			label: 'Template details heading',
		},
		{
			$formkit: 'text',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-6': true },
			id: 'details_description',
			name: 'details_description',
			label: 'Template details description text',
		},
	];

	onBeforeUnmount(async () => {
		reset('updateTemplateSettings');
		reset('updateGeneralSettings');
	});
</script>
